import React from 'react';
import ContactIcons from './ContactIcons'
// import LiveFeed from './LiveFeed'

const Contacts = () => {
    return (
        <div>
            <ContactIcons />
            {/* <LiveFeed /> */}
        </div>
    );
}

export default Contacts;