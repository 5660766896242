import React from 'react';
import CoreSkills from './CoreSkills';
import Certifications from './Certifications';
import TechStack from './TechStack'



const Skills = (props) => {
    return (
        <div>
            <CoreSkills />
            <TechStack />
            <Certifications />
        </div>
    );
}

export default Skills;