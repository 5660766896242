import React from 'react';
import styled from 'styled-components'

const AboutPage = styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 3vh;
    color: ${props => props.theme.third};
    margin-top: 15vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom:10px;
    width: 90%;
    line-height: 4vh;
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: center;
    

    .about-article{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-content: flex-start;
        border: 2px solid ${props => props.theme.secondary};
        padding: 10px;
        width: 30vw;
        height: 50vh;
        margin: 2vh;
        box-sizing: border-box;
        border-radius: 10px;
        
    }

    .article-headline{
        color: ${props => props.theme.third};
        font-size: 4vh;
        padding: 0px;
        margin-top: 3vh;
        margin-bottom: 0vh;
        margin-right: 0vw;
        margin-left: 0vw;
        text-decoration: underline;
    }

    @media screen and (max-width: 1024px){
        .about-article{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            justify-items: center;
            align-content: flex-start;
            border: 2px solid ${props => props.theme.secondary};
            padding: 10px;
            width: 70vw;
            height: 100%;
            margin: 2vh;
            box-sizing: border-box;
        }        
    }
`

const AboutMe = () => {
    return (
        <AboutPage>
            <article className="about-article">
                <h5 className="article-headline">The Professional</h5>
                <p>I am a self-motivated, customer-focused individual with strong communication skills.
                I carry two decades of business experience in several industries in different markets.
                This experience gives me an edge to utilise in the tech space which I have chosen as a path to proceed with my business life.</p>
            </article>
            <article className="about-article">
                <h5 className="article-headline">The Marketer</h5>
                <p>I’m a marketing-oriented, strategic thinker who is results-driven with a creative and analytical mind.
                I thrive on challenges and always enjoy bringing ideas to the table; before studying at the Coder Academy, I managed creative briefs, proofread content, and worked with creative designers, developers, and tech-support teams.</p>
            </article>
            <article className="about-article">
                <h5 className="article-headline">The Team Player</h5>
                <p>I am good at building relationships with various peers. As a team player, I have an influencing character and demonstrate advanced problem-solving skills.</p>
            </article>
            <article className="about-article">
                <h5 className="article-headline">The Developer</h5>
                <p>I am always thirsty to learn, evolve, develop myself and my skills.</p>
            </article>
        </AboutPage>
    );
}

export default AboutMe;