import React, { Component } from 'react';
import DeskTopNavBar from './DeskTopNavBar'
import MobileNavBar from './MobileNavBar'
import styled from 'styled-components'

const MyNavBar = styled.nav`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    overflow-x: hidden;
`

class NavBar extends Component {
    state ={
        displayMobileNavBar: false
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.screenSizeNavBar)
    }

    componentUnmount = () => {
        window.removeEventListener('resize', this.screenSizeNavBar)
    }

    toggleMobileNavBar= () => {
        this.setState(prevState => {
            return {displayMobileNavBar: !prevState.displayMobileNavBar}
        })
    }

    // checks screen size and displays NavBar accordingly.
    screenSizeNavBar = () => {
        const screenSize = window.innerWidth
        if (this.state.displayMobileNavBar && screenSize > 1024) {
            this.setState({
                displayMobileNavBar: false
            })
        }

    }

    render() {
        return (
            <MyNavBar>
                <DeskTopNavBar
                    toggleMobileNavBar={this.toggleMobileNavBar}
                    displayMobileNavBar={this.state.displayMobileNavBar}
                />
                <MobileNavBar displayMobileNavBar={this.state.displayMobileNavBar} />
            </MyNavBar>
        );
    }
}

export default NavBar;