import React from 'react';
import styled from 'styled-components'

const CertificationsStyling = styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    color: ${props => props.theme.third};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top:5vh;


    .table, th, td {
        background-color: ${props => props.theme.secondary};
        width: 30vw;
        border-radius: 10px;
        


        @media screen and (max-width: 1024px){
            height: 10vh;
        }
    }

    .education-table{
        margin: 10px;
    }

    .cirtifications-header-cell{
        color: ${props => props.theme.third};
        font-size: 5vh;
        margin-bottom: 0vh;
        text-align: center;
    }

    .header-cell {
        font-size: 3.5vh;
        padding: 10px;
    }

    .row-cells {
        font-size: 3vh;
        padding: 10px;

    }

    .col-1, .col-2, .col-3{
        padding: 5px;
        text-align: center;
    }
`

const Certifications = () => {
    return(
        <CertificationsStyling>
            <table className="education-table">
                <tbody>
                    <tr className="cirtifications-header-cell">
                        <th colSpan="3">Education & Certifications</th>
                    </tr>
                    <tr className="header-cell">
                        <th className="col-1">Certificate</th>
                        <th className="col-2">Institute</th>
                        <th className="col-3">Year</th>
                    </tr>
                    <tr className="row-cells">
                        <td className="col-1">Diploma Of Information Technology</td>
                        <td className="col-2">Coder Academy</td>
                        <td className="col-3">2021</td>
                    </tr>
                    <tr className="row-cells">
                        <td className="col-1">Fundamentals of Digital Marketing</td>
                        <td className="col-2">Google</td>
                        <td className="col-3">2018</td>
                    </tr>
                    <tr className="row-cells">
                        <td className="col-1">Digital Marketing</td>
                        <td className="col-2">EduPristine</td>
                        <td className="col-3">2015</td>
                    </tr>
                    <tr className="row-cells">
                        <td className="col-1">Integrated Management System</td>
                        <td className="col-2">International Organisation of Standardisation</td>
                        <td className="col-3">2005</td>
                    </tr>
                    <tr className="row-cells">
                        <td className="col-1">Hazard Analysis and Critical Control Points</td>
                        <td className="col-2">International Organisation of Standardisation</td>
                        <td className="col-3">2005</td>
                    </tr>
                    <tr className="row-cells">
                        <td className="col-1">Hospitality & Culinary Arts</td>
                        <td className="col-2">Shouf High College</td>
                        <td className="col-3">2001</td>
                    </tr>
                </tbody>
            </table>
        </CertificationsStyling>

    )
}

export default Certifications