import React from 'react';
import styled from 'styled-components'

const StyledHistory = styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    color: ${props => props.theme.third};
    margin-top: 15vh;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 96%;

    @media screen and (max-width: 1024px){
        flex-flow: column;
        align-items: center;
        justify-items: space-around;
        width: 97%;
    }

    .company{
        border: 2px solid ${props => props.theme.secondary};
        padding: 5px;
        width: 45%;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        background-color: ${props => props.theme.secondary};

        @media screen and (max-width: 1024px){
            width: 100%;
            margin: 5px;
            padding: 5px;
            box-sizing: border-box;
        }

    }

    .title {
        text-decoration: none;
        color: ${props => props.theme.third};
        font-size: 4vh;
        padding: 0px;
        margin-left: 0vw;
        margin-right: 0vw;
        margin-top: 2vh;
        margin-bottom: 2vh;

        &:hover {
            color: ${props => props.theme.primary};
        }   
    }

    .title-no-link {
        text-decoration: none;
        color: ${props => props.theme.third};
        font-size: 4vh;
        padding: 0px;
        margin-left: 0vw;
        margin-right: 0vw;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

    .intro, .content{
        font-size: 3vh;
        margin: 0px;
        padding: 0px;  
    }

    .position{
        font-size: 3vh;
        margin: 0px;
        padding: 0px;
        color: ${props => props.theme.primary};
    }

`

const History = () => {
    return (
        <StyledHistory>
            <article className="company">
                <h3><a className="title" href="https://www.almulla-behbehani.com.kw/" rel="noreferrer" target="_blank">Al Mulla & Behbehani Motors Company</a></h3>
                <h4 className="intro">AlMulla and Behbehani Motor Company is the sole representative of Fiat Chrysler Automobile brands in Kuwait.</h4>
                <h5 className="position">Position: Assistant General Manager / Head of Marketing from November 2017 to June 2018.</h5>
                <p className="content">Heading the marketing operations of the group including: Alfa Romeo, Abarth, Chrysler, Dodge, Fiat, Jeep, RAM & Mopar.</p>
            </article>
            <article className="company">
                <h3><a className="title" href="http://www.faa.com.kw/" rel="noreferrer" target="_blank">Fouad Alghanim & Sons Automotive</a></h3>
                <h4 className="intro">Fouad Alghanim and Sons Automotive Company is the official dealer of Audi, Lamborghini and Škoda in the State of Kuwait.</h4>
                <h5 className="position">Position: Marketing Manager for Audi, Lamborghini and Škoda	from April 2015 to July 2017.</h5>
                <p className="content">Heading the marketing operations for new and used cars as well as after sales within the State of Kuwait, reporting directly to the general manager and working in alignment with the regional offices of each brand.</p>
            </article>
            <article className="company">
                <h3 className="title-no-link">Arabian Motors Group</h3>
                <h4 className="intro">Arabian Motors Group the former official sole dealer of Ford, Lincoln, Motor Craft and Quick Lane” in the State of Kuwait.</h4>
                <h5 className="position">Position: Marketing Manager for Ford and Lincoln	from August 2014 to April 2015.</h5>
                <p className="content">Managing the group’s marketing operations for new and used cars as well as after sales within the State of Kuwait, reporting to the operations manager and working in alignment with Ford Middle East head office.</p>
            </article>
            <article className="company">
                <h3><a className="title" href="https://www.fp7.com/" rel="noreferrer" target="_blank">FP7 McCANN</a></h3>
                <h4 className="intro">FP7 McCANN is an advertising agency and a division of MCN network which represents IPG in the MENA region.</h4>
                <h5 className="position">Position: Account Manager from June 2013 to July 2014.</h5>
                <p className="content">Reporting directly to the Managing Director of Kuwait office, handling two major accounts, Arabian Motors Group “the official dealer for Ford, Lincoln, Motor Craft and Quick Lane in Kuwait, Ahli United Bank and handling regional requests on small accounts.</p>
            </article>
            <article className="company">
                <h3><a className="title" href="https://www.zafafmagazine.com/" rel="noreferrer" target="_blank">Nine Peas Media</a></h3>
                <h4 className="intro">Nine Peas Media, Advertising, Publishing and Distribution Company. The publishers of Zafaf Magazine.</h4>
                <h5 className="position">Position: Sales and Marketing Manager from January 2010 to June 2013.</h5>
                <p className="content">Reporting directly to the Managing Director and Editor in Chief, holding ultimate responsibility for several products one of which is a leader in the niche market of weddings in the State of Kuwait.</p>
            </article>
            <article className="company">
                <h3 className="title-no-link">Media Box</h3>
                <h4 className="intro">Media Box for media, advertising, publishing and distribution.</h4>
                <h5 className="position">Position: Media Sales Manager from January 2007 to January 2010.</h5>
                <p className="content">Media Box is a Media, Advertising, Publishing and Distribution company and was one of the leading media houses in the State of Kuwait.</p>
            </article>
            <article className="company">
                <h3 className="title-no-link">La Baguette</h3>
                <h4 className="intro">La Baguette is a bakery and pastry chain with multiple stores across the State of Kuwait.</h4>
                <h5 className="position">Position: Catering Sales Manager from November 2004 to January 2007.</h5>
            </article>
            <article className="company">
                <h3 className="title-no-link">Delice De France</h3>
                <h4 className="intro">Delice De France is a fine-dining French gourmet bakery and restaurant and five pop-ups.</h4>
                <h5 className="position">Position: Restaurant Manager from June 2003 to November 2004.</h5>
            </article>
            <article className="company">
                <h3 className="title-no-link">Mövenpick Hotel & Resort Beirut</h3>
                <h5 className="position">Position: Bartender from	2001 to 2002.</h5>
            </article>
            <article className="company">
                <h3 className="title-no-link">Beirut Marriott Hotel</h3>
                <h5 className="position">Position: Bartender from	1998 to 2001.</h5>
            </article>
        </StyledHistory>
    );
}

export default History;