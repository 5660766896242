import React from 'react';
import styled from 'styled-components';
import cssLogo from '../../data/images/cssLogo.svg';
import html5Logo from '../../data/images/html5Logo.svg';
import jsLogo from '../../data/images/jsLogo.svg';
import psqlLogo from '../../data/images/psqlLogo.svg';
import railsLogo from '../../data/images/railsLogo.svg';
import reactLogo from '../../data/images/reactLogo.svg';
import rubyLogo from '../../data/images/rubyLogo.svg';
import sassLogo from '../../data/images/sassLogo.svg';
import gitLogo from '../../data/images/gitLogo.svg'




const StackLogos = styled.div`
    display: flex;
    padding: 0px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 7vh;
    justify-content: center;

    @media screen and (max-width: 1024px){
        margin-top: 5vh;
    }

    .logos-table {
        margin: 10px;
    }

    .table, th, td {
        background-color: ${props => props.theme.secondary};
        box-sizing: border-box;
        height: 10vh;
        border-radius: 10px;
        width: 20vw;

        @media screen and (max-width: 1024px){
            width: 10vw;
        }
    }

    .col-1, .col-2, .col-3 {
        @media screen and (max-width: 1024px){
            padding: 0px;
            width: 45vw;
        }
    }


    .title {
        font-family: 'Pathway Gothic One', sans-serif;
        font-size: 5vh;
        font-weight: bold;
        color: ${props => props.theme.third};
        margin-bottom: 2px;
        padding: 2px;
        box-sizing: border-box;
        width: 25%;
        border-radius: 10px;
    }
`

const TechStack = (props) => {
    return (
        <StackLogos>
            <table className="logos-table">
                <tbody>
                    <tr className="title">
                        <th colSpan="3">Tech Stack</th>
                    </tr>
                    <tr>
                        <th className="col-1"><img src={jsLogo} alt="JavaScript Logo"/></th>
                        <th className="col-2"><img src={gitLogo} alt="GIT Logo"/></th>
                        <th className="col-3"><img src={cssLogo} alt="CSS Logo"/></th> 
                    </tr>
                    <tr>
                        <th className="col-1"><img src={reactLogo} alt="React Logo"/></th>
                        <th className="col-2"><img src={psqlLogo} alt="PostgreSQL Logo"/></th>
                        <th className="col-3"><img src={html5Logo} alt="HTML5 Logo"/></th>
                    </tr>
                    <tr>
                        <th className="col-1"><img src={rubyLogo} alt="Ruby Logo"/></th>
                        <th className="col-2"><img src={railsLogo} alt="Rails Logo"/></th>
                        <th className="col-3"><img src={sassLogo} alt="SASS Logo"/></th>
                    </tr>
                </tbody>
            </table>   
        </StackLogos>
    );
}

export default TechStack;