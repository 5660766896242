import React from 'react';
import styled from 'styled-components';
import burgerMenu from '../../data/images/burgerMenu.svg';
import NavLinks from './NavLinks'

const MyDeskTopNavBar = styled.nav`
    font-family: 'Pathway Gothic One', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top:0vh;
    width: 100%;
    background: ${props => props.theme.secondary};
    color: ${props => props.theme.primary};
    height: 10vh;
    box-shadow: 0px 2.5px 2.5px ${props => props.theme.accent};

    .logo {
        font-size: 9vh;
        font-weight: bold;
        text-shadow: 1px 1px 1px ${props => props.theme.third};
    }

    .nav-links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;
        width: 70vw;
        list-style: none;

        @media screen and (max-width: 1024px){
            display: none;
        }

    }

    .links {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 10vh;
        color: white;
        font-size: 3vh;
        font-weight: bold;
        text-decoration: none;
        padding: 0 1rem;
        border-radius: 10px;

        &:focus {
            background: rgba(0, 0, 0, 0.1);
            outline: none;
        }

        &:hover{
            color: ${props => props.theme.primary};
        }

        &::after{
            content: '';
            height: 2px;
            width: 0px;
            background: ${props => props.theme.primary};
            display: block;
            transition: width 0.5s;
        }

        
    }&:hover::after{
            transform: width;
            width: 100%;
        }
`

const MyMobileNavButton = styled.button`
    background: transparent;
    height: 6vh;
    width: 6vh;
    border: none;
    display: none;

    transition: transform 1s;
    transform: rotate(${props => props.displayMobileNavBar ? ("90deg") : ("0deg")});

    &focus{
        outline: none;
    }

    @media screen and (max-width: 1024px){
        display: block;
    }

`
const DeskTopNavBar = (props) => {
    return (
        <MyDeskTopNavBar>
            <div className="logo">Ibsio</div>
            <NavLinks />
            <MyMobileNavButton
                displayMobileNavBar={props.displayMobileNavBar}
                onClick={props.toggleMobileNavBar}
            >
                <img src={burgerMenu} alt="Menu Button"/>
            </MyMobileNavButton>
        </MyDeskTopNavBar>
    );
}

export default DeskTopNavBar;