import React from 'react';
import styled from 'styled-components'
import autoParts from '../../data/images/autoParts.png'
import ibsioPortfolio from '../../data/images/ibsioPortfolio.png'
import kmecMarketplace from '../../data/images/kmecMarketplace.png'
import superPaws from '../../data/images/superPaws.png'

const StyledProjects = styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    color: ${props => props.theme.third};
    margin-top: 15vh;
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    flex-direction: column;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-around;
    box-sizing: border-box;
    text-shadow: 0 0 0 0;

   
    
    .card {
        width: 40%;
        border: 1px solid ${props => props.theme.secondary};
        padding: 5px;
        border-radius: 10px;
        transition: width 1.5s;
        margin-bottom: 5vh;
        background-color: ${props => props.theme.secondary};
       
       &:hover {
           transform: width;
            width: 42%;
        }
    }
    .name{
        margin: 0px;
        font-size: 4vh;
    }

    .description{
        margin: 0px;
        font-size: 3vh;
    }

    .summary{
        margin: 0px;
        font-size: 2vh;
    }

    .tech-stack{
        margin: 0px;
        font-size: 3.5vh;
        font-weight: normal;
        color: ${props => props.theme.primary};
        text-shadow: 0 0 0 0;

    }

    .repo{
        color: ${props => props.theme.third};
        text-decoration: none;
        margin-top: 0px;
        padding: 0px;
        font-size: 2vh;
        display: flex;

        &:hover {
            color: ${props => props.theme.primary};
        }
    }

    .screenshot {
        width: 100%;
        transition: width 1s;
    }

    @media screen and (max-width: 1024px){
        margin-top: 15vh;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        
        .card {
            width: 90%;
            margin-bottom: 2vh;
            box-sizing: border-box;

            &:hover{
                transform: width;
                width: 90%;
            }
        }
        .screenshot{
            width: 100%;
        }
    }

    .kmec-site {
        color: ${props => props.theme.third};
        text-decoration: none;
        margin-top: 0px;
        padding: 0px;
        display: flex;
        align-self: flex-end;
        text-indent: 3px;
        font-size: 2.5vh;
        display: inline-flex;


        &:hover {
            color: ${props => props.theme.primary};
        }
    }
`


const Projects = (props) => {
    return (
        <StyledProjects>
            <article className="card">
                <h2 className="name">Project Name: Auto Parts.</h2>
                <h4 className="tech-stack">Tech Stack: Ruby.</h4>
                <h4 className="description">Description: Terminal App.</h4>
                <p className="summary">Summary:  A basic concept for cars parts stock management.</p>
                <img className="screenshot" src={autoParts} alt="App Screenshot" />
                <a className="repo" href="https://github.com/ibsio/AutoParts-TerminalApp" rel="noreferrer" target="_blank">Visit the repo to read more >></a>
            </article>
            <article className="card">
                <h2 className="name">Project Name: Portfolio.</h2>
                <h4 className="tech-stack">Tech Stack: HTML and CSS</h4>
                <h4 className="description">Description: My first personal portfolio.</h4>
                <p className="summary">Summary: A basic portfolio website.</p>
                <img className="screenshot" src={ibsioPortfolio} alt="Portfolio Screenshot" />
                <a className="repo" href="https://github.com/ibsio/myportfolio" rel="noreferrer" target="_blank">Visit the repo to read more >></a>
            </article>
            <article className="card">
                <h2 className="name">Project Name: KMEC.</h2>
                <h4 className="tech-stack">Tech-Stack: Ruby, Rails, JavaScript, PSQL, HTML and CSS.</h4>
                <h4 className="description">Description:<a className="kmec-site" href="https://kmec.herokuapp.com" rel="noreferrer" target="_blank">Click here to visit the web app >></a></h4>
                <p className="summary">Summary: Two sided marketplace.</p>
                <img className="screenshot" src={kmecMarketplace} alt="KMEC Screenshot" />
                <a className="repo" href="https://github.com/ibsio/KMEC" rel="noreferrer" target="_blank">Visit the repo to read more >></a>
            </article>
            <article className="card">
                <h2 className="name">Project Name: Super Paws.</h2>
                <h4 className="tech-stack">Tech-Stack: JavaScript, HTML and CSS.</h4>
                <h4 className="description">Description: Experimenting JS fetch function.</h4>
                <p className="summary">Summary: Fetching pups images with superhero names.</p>
                <img className="screenshot" src={superPaws} alt="Super Paws Screenshot" />
                <a className="repo" href="https://github.com/ibsio/SuperPaws" rel="noreferrer" target="_blank">Visit the repo to read more >></a>
            </article>
        </StyledProjects>
    );
}

export default Projects;