import React from 'react'
import styled from 'styled-components'


const CoreSkillsStyling = styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    color: ${props => props.theme.third};
    list-style: none;
    font-weight: normal;
    font-size: 3vh;
    display: flex;
    flex-direction: column;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0px;
    justify-content: center;
    
    

    .skills-1{
        border: 2px solid ${props => props.theme.secondary};;
        padding: 10px;
        margin: 10px;
        line-height: 5vh;
        width: 20vw;
        text-align: center;
        border-radius: 10px;

        @media screen and (max-width: 1024px){
        width: 50%;
        }
    }
`

const HeadLine =styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 5vh;
    color: ${props => props.theme.third};
    text-align: center;
    text-decoration: underline;
    margin-bottom: 0px;
    margin-top: 12vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom:10px;
`



const CoreSkills = () => {
    return (
        <div>
            <HeadLine>Core Skills</HeadLine>
            <CoreSkillsStyling>
                <div className="skills-1">
                    <li>Coaching</li>
                    <li>Leadership</li>
                    <li>Negotiation</li>
                    <li>Client Servicing</li>
                    <li>Digital Marketing</li>
                    <li>Strategic Marketing</li>
                </div>
                <div className="skills-1">
                    <li>Project Management</li>
                    <li>Account Management</li>
                    <li>Marketing Management</li>
                    <li>Social Media Marketing</li>
                    <li>Campaigns Development</li>
                    <li>Social Media Management</li>
                </div>
            </CoreSkillsStyling>
        </div>
    )
}

export default CoreSkills