import React from 'react';
import styled from 'styled-components'
import Contacts from './Contacts'

const HomePage = styled.div`
    font-family: 'Pathway Gothic One', sans-serif;
    color: ${props => props.theme.third};
    text-align: center;
    margin-top: 15vh;
    font-size: 8vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: 750px){
        font-size: 5vh;
    }

    .message{
        margin: 2.5vh;
    }
`


const Home = () => {
    return (
        <HomePage>
            <p className="message">hi_i_am_ibsio.</p>
            <p className="message">full_stack_developer.</p>
            <Contacts />
        </HomePage>
    );
}

export default Home;