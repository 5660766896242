import React from 'react';
import styled from 'styled-components'
import myResume from '../../data/documents/ihab-ibsio-resume.pdf'

const StyledResumeButton = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: row;
    justify-items: flex-end;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    @media screen and (max-width: 768px){
        justify-content: space-around;
    }
    .button{
        background-color: ${props => props.theme.primary};
        height: 7vh;
        text-align: center;
        border: 0px;
        border-radius: 10px;
        box-shadow: 0px 0px 1px 2px ${props => props.theme.third};
        padding: 5px;
        @media screen and (max-width: 385px){
            margin-top: 1vh;
            margin-right: 3vw;
            margin-bottom: 1vh;
        }
    }
    .download{
        background-color: ${props => props.theme.primary};
        text-decoration: none;
        color: ${props => props.theme.third};
        font-family: 'Pathway Gothic One', sans-serif;
        font-size: 2.3vh;
        margin: 0px;
        padding: 1px;
        text-align: center;
    }
`

const ResumeButton = (props) => {
    return (
        <StyledResumeButton>
            <button className="button"><a className="download" href={myResume} rel="noreferrer" target="_blank" download>Download Resume</a></button>
        </StyledResumeButton>
    );
}

export default ResumeButton;