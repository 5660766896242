import React from 'react';
import { Link } from 'react-router-dom'
import ResumeButton from '../pages/ResumeButton'


const NavLinks = (props) => {
    let tempTabIndex
    if (props.isMobileLink) {
        tempTabIndex = "-1"
    }
    return (
        <ul className="nav-links">
            <li>
                <Link to="/" className="links" tabIndex={tempTabIndex}>Home</Link>
            </li>
            <li>
                <Link to="/about" className="links" tabIndex={tempTabIndex}>About Me</Link>
            </li>
            <li>
                <Link to="skills" className="links" tabIndex={tempTabIndex}>Skills & Certificates</Link>
            </li>
            <li>
                <Link to="/projects" className="links" tabIndex={tempTabIndex}>Projects</Link>
            </li>
            <li>
                <Link to="/history" className="links" tabIndex={tempTabIndex}>Work History</Link>
            </li>
            {/* <li>
                <a className="links" href={myResume} rel="noreferrer" target="_blank" download>Download My Resume</a>
            </li> */}
            <li>
                <ResumeButton />
            </li>
        </ul>
    );
}

export default NavLinks;