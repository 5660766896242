import React from 'react';
import NavBar from './navigation/NavBar';
import { BrowserRouter, Route } from 'react-router-dom';
import AboutMe from '../components/pages/AboutMePage';
import Skills from '../components/pages/SkillsCertificatePage';
import Projects from '../components/pages/ProjectsPage';
import History from '../components/pages/WorkHistoryPage';
import Home from '../components/pages/HomePage';
import Footer from './pages/Footer'


const App = () => {
  return (
      <BrowserRouter>
        <NavBar />
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={AboutMe} />
        <Route exact path="/skills" component={Skills} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/history" component={History} />
        <Footer />
      </BrowserRouter>
  )
}

export default App
