import React from 'react';
import styled from 'styled-components'
import twitterIcon from '../../data/images/twitterIcon.svg'
import linkedinIcon from '../../data/images/linkedinIcon.svg'
import githubIcon from '../../data/images/githubIcon.svg'
import emailIcon from '../../data/images/emailIcon.svg'
import phoneIcon from '../../data/images/phoneIcon.svg'

const StyledContactIcons = styled.div`
    margin-top: 2.5vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 2.5vh;
    display: flex;
    flex-direction: column;
    flex-flow: row;
    justify-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;

    @media screen and (max-width: 768px){
        justify-content: space-around;
        margin-top: 3vh;
        .icon{
            margin: 10px;
            padding: 0px;
        }
    }
    .button{
        background-color: ${props => props.theme.secondary};
        height: 7vh;
        text-align: center;
        border: 0px;
        border-radius: 10px;
        box-shadow: 0px 0px 1px 2px black;
        padding: 1px;
        @media screen and (max-width: 385px){
            margin-top: 1vh;
            margin-right: 3vw;
            margin-bottom: 1vh;
        }
    }
    .download{
        background-color: ${props => props.theme.secondary};
        text-decoration: none;
        color: black;
        font-family: 'Pathway Gothic One', sans-serif;
        font-size: 2.3vh;
        margin: 0px;
        padding: 1px;
        text-align: center;
    }
`

const ContactIcons = (props) => {
    return (
        <StyledContactIcons>
            <a className="icon" href="https://twitter.com/ibsio" rel="noreferrer" target="_blank" alt="Twitter Logo"><img src={twitterIcon} alt="Twitter Logo"/></a>
            <a className="icon" href="https://linkedin.com/in/ibsio" rel="noreferrer" target="_blank" alt="LinkedIn Logo"><img src={linkedinIcon} alt="Twitter Logo"/></a>
            <a className="icon" href="https://github.com/ibsio" rel="noreferrer" target="_blank"><img src={githubIcon} alt="GitHub Logo"/></a>
            <a className="icon" href="mailto:ihab@inbox.com" rel="noreferrer"><img src={emailIcon} alt="Email Logo"/></a>
            <a className="icon" href="tel:+61488252837" rel="noreferrer"><img src={phoneIcon} alt="Telephone Logo"/></a>
        </StyledContactIcons>
    );
}

export default ContactIcons;