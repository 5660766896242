import React from 'react';
import styled from 'styled-components'
import NavLinks from './NavLinks'


const MyMobileNavBar = styled.nav`
    font-family: 'Pathway Gothic One', sans-serif;
    width: 60vw;
    background: ${props => props.theme.secondary};
    box-shadow: -1px 2.5px 2.5px ${props => props.theme.accent};
    align-self: flex-end;
    position: fixed;
    top: 10vh;

    margin-bottom: 15px;

    transition: transition 0.5s;
    transform: translateX(${props => props.displayMobileNavBar ? ("0%") : ("calc(100% + 15px)")});

    .nav-links {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    height: 60vh;
    list-style: none;
    }

    .links {
        color: white;
        font-size: 3vh;
        font-weight: bold;
        text-decoration: none;

        &:focus {
            color: ${props => props.theme.primary};
        }
    }


`
const MobileNavBar = (props) => {
    return (
        <MyMobileNavBar displayMobileNavBar = {props.displayMobileNavBar}>
            <NavLinks isMobileLink = {true} />
        </MyMobileNavBar>
    );
}

export default MobileNavBar;