import React from 'react';
import styled from 'styled-components'

const StyledFooter = styled.footer`
    display: block;
    font-family: 'Pathway Gothic One', sans-serif;
    color: ${props => props.theme.third};
    padding: 20px;
    text-align: center;
    font-size: 1.5vh;

`

const Footer = () => {
    return(
        <StyledFooter>
             <footer className="footer">ibsio 2021 &reg;</footer>
        </StyledFooter>
    )
}
export default Footer;